
import React from 'react';
import { useContentState, useAuthDispatch } from '../../context';

const HeadSection = props =>{
    const dispatch = useAuthDispatch();

  const contentDetails = useContentState();
  return (
    <section id="home" className="section welcome-area overflow-hidden d-flex align-items-center ">
      <div className="container">


        <div className="row align-items-center">
                    {/* Welcome Intro Start */}
                    <div className="col-12 col-lg-6 order-2 order-lg-1">
                        {/* Discover Thumb */}
                        <div className="service-thumb discover-thumb mx-auto pt-5 pt-lg-0">
                        <img src={  contentDetails.HeadSectionContent.img} alt="" />
                        </div>
                    </div>
                    <div className="col-12 col-md-7 col-lg-6">
                        <div className="">
                            <p className="text-blue  h2-font  ">{"    "}</p>
                            <p className="text-blue  h2-font  ">{"    "}</p>
                        </div>
                        <div className="welcome-intro">
                        <h2 className="text-blue">{contentDetails.HeadSectionContent.heading}</h2>
                        <p className="text-blue  h2-font  ">{contentDetails.HeadSectionContent.content}</p>
                        {/* Store Buttons */}
                        <div className="schedule-text  ">
                            <a href="/cv" className="landing-button btn  mt-1">{contentDetails.HeadSectionContent.button}</a>
                        </div>
                        </div>
                    </div>

        </div>



      </div>
      <div className="shape-bottom">

      </div>
      {
      //<button onClick={start_payment}>
      //  Activate Lasers
      //</button>


      }
    </section>
  );
}

export default HeadSection;