import React , {useEffect, useState} from 'react';
import { useAuthDispatch } from '../../context';
import LoadingSection from '../Loading/LoadingOne';

const Header = ({
    setLoadState,
    loadState,
    loadHeader,
    loadMessage,
    loadButtonText,
    loadButtonLink
    }) => {
    const [isLoggedIn, setIsLoggedIn] = useState('');
    const dispatch = useAuthDispatch();
  
    const handleClose = () => {
        setLoadState(false);
    };

    useEffect(() => {
        setIsLoggedIn( localStorage.getItem('token'));
      }, [isLoggedIn]);
    
      useEffect(() => {
        console.log('EXECUTE: Header/setMessage: ',  loadMessage)
      }, [loadMessage]);


    // Function will execute on click of button
    const onButtonClickCV= () => {
        // using Java Script method to get PDF file
        fetch('/img/alejandro_coronado_resume.pdf').then(response => {
            response.blob().then(blob => {
                // Creating new object of PDF file
                const fileURL = window.URL.createObjectURL(blob);
                // Setting various property values
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = 'alejandro_coronado_resume.pdf';
                alink.click();
            })
        })
    }

    const onButtonClickResume= () => {
        // using Java Script method to get PDF file
        fetch('/img/alejandro_coronado_resume.pdf').then(response => {
            response.blob().then(blob => {
                // Creating new object of PDF file
                const fileURL = window.URL.createObjectURL(blob);
                // Setting various property values
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = 'alejandro_coronado_resume.pdf';
                alink.click();
            })
        })
    }

    //console.log('Header/localstorage: ', localStorage)
    return (
      <header className="navbar navbar-sticky navbar-expand-lg navbar-dark">
        <div className="container position-relative">
          <a className="navbar-brand" href="/">
            <img className="navbar-brand-regular logo-white"   src="/img/logo-white.png" alt="brand-logo" />
            <img className="navbar-brand-sticky logo-purple " src="/img/logo.png" alt="sticky brand-logo" />
          </a>
          <button className="navbar-toggler d-lg-none" type="button" data-toggle="navbarToggler" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon" />
          </button>

          <div className="navbar-inner ">
            {/*  Mobile Menu Toggler */}
            <button className="navbar-toggler d-lg-none" type="button" data-toggle="navbarToggler" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon" />
            </button>
            <nav>
              <ul className="navbar-nav menu-font" id="navbar-nav">
              <li className="nav-item">
                  <a className="nav-link " href="/">Inicio</a>
                </li>

                <li className="nav-item">
                  <a className="nav-link " href="/photos">Photography</a>
                </li>

                <li className="nav-item">
                  <a className="nav-link" href="/blog">Blog</a>
                </li>

                <div className="nav-item">
                        <a onClick={onButtonClickCV} className="header-button btn  mt-3">CV</a>
                </div>

                <li className="nav-item">
                    <a onClick={onButtonClickResume} className="header-button btn  mt-3">Resume</a>
                </li>
              </ul>
            </nav>
          </div>
          <LoadingSection 
            loadState = {loadState}
            handleClose={handleClose}
            loadHeader = {loadHeader}
            loadMessage = {loadMessage}
            loadButtonText = {loadButtonText}
            loadButtonLink ={loadButtonLink}
            />

        </div>
    </header>
    );
  
}

export default Header;